import React, { useState } from 'react';
import './contacts.css';
import AmicaRoseNavbar from './AmicaRoseNavbar';

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            const response = await fetch('https://84bjzd22ak.execute-api.eu-west-1.amazonaws.com/prod/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                mode: 'cors', 
                body: JSON.stringify({ name, email, subject, message }),
            });
            const data = await response.json();
            setResponseMessage(data.message);
            setName('');
            setEmail('');
            setSubject('');
            setMessage('');
        } catch (error) {
            console.error('Error:', error);
            setResponseMessage('Failed to send email. Please try again later.');
        }
    };

    return (
        <div>
            <div className="amica-rose-container">
                <AmicaRoseNavbar />
            </div>
            <div className="contact-content">
                <div className="contact-email">
                    <h2>Contact Us</h2>
                    <div className="email-instagram">
                        <p>Email: <a href="mailto:info@amica-stays.co.uk">info@amica-stays.co.uk</a></p>
                    </div>
                </div>
                <hr className="contact-divider" />
                <div className="contact-form-wrap">
                    <h2>Get in Touch</h2>
                    {responseMessage && <p className="response-message">{responseMessage}</p>}
                    <form className="contact-form" onSubmit={handleSubmit}>
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <label htmlFor="subject">Subject</label>
                        <input
                            type="text"
                            id="subject"
                            name="subject"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            required
                        />
                        <label htmlFor="message">Message</label>
                        <textarea
                            id="message"
                            name="message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        ></textarea>
                        <p className="gdpr-text">
                            By clicking on the SEND button, you provide your consent to process your personal data in accordance with our privacy policy. Your information will be used solely to respond to your inquiry.
                        </p>
                        <button type="submit" className="send-button">
                            SEND
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Contact;
