import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ChannelNavbar from './ChannelNavbar';

const Reservations = () => {
  const [reservations, setReservations] = useState([]);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove the token
    navigate('/login'); // Redirect to login page
  };

  useEffect(() => {
    const fetchReservations = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch('http://127.0.0.1:5000/api/channel/reservations', {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        setReservations(data.reservations);
      } catch (error) {
        console.error('Error fetching reservations:', error);
      }
    };

    fetchReservations();
  }, []);

  return (
    <div className="container">
      <ChannelNavbar />
      <div className="reservationsWrap">
        <h1>Reservations</h1>
        <ul className="reservationsList">
          {reservations.map((reservation) => (
            <li key={reservation.id}>
              Room: {reservation.room}, Guest: {reservation.guest}, Check-in: {reservation.check_in}, Check-out: {reservation.check_out}
            </li>
          ))}
        </ul>
        <button className="logoutButton" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default Reservations;
