import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './messages.css';
import ChannelNavbar from './ChannelNavbar';

const Messages = () => {
  const [messages, setMessages] = useState([]);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove the token
    navigate('/login'); // Redirect to login page
  };

  useEffect(() => {
    const fetchMessages = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch('http://127.0.0.1:5000/api/messages', {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        setMessages(data.messages);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();
  }, []);

  return (
    <div className='container'>
      <ChannelNavbar />
    <div className="messagesContainer">
      <div className="messagesWrap">
        <h1>Messages</h1>
        <ul className="messagesList">
          {messages.map((msg, idx) => (
            <li key={idx}>{msg}</li>
          ))}
        </ul>
        <button className="logoutButton" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
    </div>
  );
};

export default Messages;
