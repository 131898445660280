import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ChannelNavbar from './ChannelNavbar';

const Availability = () => {
  const [availability, setAvailability] = useState([]);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove the token
    navigate('/login'); // Redirect to login page
  };

  useEffect(() => {
    const fetchAvailability = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch('http://127.0.0.1:5000/api/channel/availability', {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        setAvailability(data.availability);
      } catch (error) {
        console.error('Error fetching availability:', error);
      }
    };

    fetchAvailability();
  }, []);

  return (
    <div className="container">
      <ChannelNavbar />
      <div className="availabilityWrap">
        <h1>Availability</h1>
        <ul className="availabilityList">
          {availability.map((item, idx) => (
            <li key={idx}>
              Room: {item.room}, Date: {item.date}, Available: {item.is_available ? 'Yes' : 'No'}
            </li>
          ))}
        </ul>
        <button className="logoutButton" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default Availability;
