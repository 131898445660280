import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ChannelNavbar from './ChannelNavbar';

const Rates = () => {
  const [rates, setRates] = useState([]);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove the token
    navigate('/login'); // Redirect to login page
  };

  useEffect(() => {
    const fetchRates = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch('http://127.0.0.1:5000/api/channel/rates', {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        setRates(data.rates);
      } catch (error) {
        console.error('Error fetching rates:', error);
      }
    };

    fetchRates();
  }, []);

  return (
    <div className="container">
      <ChannelNavbar />
      <div className="ratesWrap">
        <h1>Rates</h1>
        <ul className="ratesList">
          {rates.map((rate, idx) => (
            <li key={idx}>
              Room: {rate.room}, Date: {rate.date}, Rate: ${rate.rate}
            </li>
          ))}
        </ul>
        <button className="logoutButton" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default Rates;
