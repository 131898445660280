import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ChannelNavbar from './ChannelNavbar';

const Inbox = () => {
  const [inbox, setInbox] = useState([]);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove the token
    navigate('/login'); // Redirect to login page
  };

  useEffect(() => {
    const fetchInbox = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch('http://127.0.0.1:5000/api/channel/inbox', {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        setInbox(data.inbox);
      } catch (error) {
        console.error('Error fetching inbox messages:', error);
      }
    };

    fetchInbox();
  }, []);

  return (
    <div className="container">
      <ChannelNavbar />
      <div className="inboxWrap">
        <h1>Inbox</h1>
        <ul className="inboxList">
          {inbox.map((message) => (
            <li key={message.id}>
              <strong>Subject:</strong> {message.subject} <br />
              <strong>From:</strong> {message.sender} <br />
              <strong>Message:</strong> {message.message}
            </li>
          ))}
        </ul>
        <button className="logoutButton" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default Inbox;
