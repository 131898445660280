import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './amicarosenavbar.css'; // Import CSS file

const ChannelNavbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav className="amica-rose-navbar">
            <Link to="/channel/reservations">
                <img src="/amica/Amica sign bigger.png" alt="Amica Logo" className="amica-rose-logo" />
            </Link>
            <div className="amica-rose-menu-toggle" onClick={toggleMenu}>
                <svg viewBox="0 0 24 24" className="amica-rose-menu-icon">
                    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
                </svg>
            </div>
            <ul className={`amica-rose-nav-links ${isMenuOpen ? 'active' : ''}`}>
                <li><Link to="/channel/reservations">Reservations</Link></li>
                <li><Link to="/channel/availability">Availability</Link></li>
                <li><Link to="/channel/rates">Rates</Link></li>
                <li><Link to="/channel/inbox">Inbox</Link></li>
                <li><Link to="/channel/messages">Messages</Link></li>
                <li><Link to="/housekeeping">Housekeeping</Link></li>
                {/* <li><Link to="/">Home</Link></li> */}
            </ul>
        </nav>
    );
};

export default ChannelNavbar;
