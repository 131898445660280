import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './housekeeping.css';

const Housekeeping = () => {
  const [tasks, setTasks] = useState([]);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove the token
    navigate('/login'); // Redirect to login page
  };

  useEffect(() => {
    const fetchTasks = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch('http://127.0.0.1:5000/api/housekeeping', {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        setTasks(data.tasks);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };

    fetchTasks();
  }, []);

  return (
    <div className="housekeepingContainer">
      <div className="housekeepingWrap">
        <h1>Housekeeping Tasks</h1>
        <ul className="tasksList">
          {tasks.map((task, idx) => (
            <li key={idx}>{task}</li>
          ))}
        </ul>
        <button className="logoutButton" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default Housekeeping;
