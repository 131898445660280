import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element: Component, isAuthenticated }) => {
  const [loading, setLoading] = useState(true);
  const [isTokenValid, setIsTokenValid] = useState(false);

  useEffect(() => {
    const validateToken = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setIsTokenValid(false);
        setLoading(false);
        return;
      }

      try {
        const response = await fetch('http://127.0.0.1:5000/api/validate-token', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setIsTokenValid(response.ok);
      } catch (error) {
        console.error('Token validation error:', error);
        setIsTokenValid(false);
      } finally {
        setLoading(false);
      }
    };

    validateToken();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show a loader while validating the token
  }

  if (!isAuthenticated && !isTokenValid) {
    return <Navigate to="/login" />;
  }

  return Component;
};

export default ProtectedRoute;
