import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './components/Home/Home';
import AmicaGuesthouse from './components/AmicaGuesthouse/Overview';
import Rooms from './components/AmicaGuesthouse/Rooms';
import GuestMap from './components/AmicaGuesthouse/Map';
import GuestContacts from './components/AmicaGuesthouse/Contacts';
import AmicaRose from './components/AmicaRose/Overview';
import Gallery from './components/AmicaRose/Gallery';
import Map from './components/AmicaRose/Map';
import Contacts from './components/AmicaRose/Contacts';
import CookieBanner from './CookieBanner';
import PrivacyPolicy from './PrivacyPolicy';
import Login from './Login';
import Messages from './components/Channel/Messages';
import Housekeeping from './components/Channel/Housekeeping';
import ProtectedRoute from './ProtectedRoute';
import Reservations from './components/Channel/Reservations';
import Availability from './components/Channel/Availability';
import Rates from './components/Channel/Rates';
import Inbox from './components/Channel/Inbox';



const AppContent = () => {
  const location = useLocation();
  // const navigate = useNavigate();

  const showCookieBanner = location.pathname !== '/PrivacyPolicy';
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = () => {
    setIsAuthenticated(true); // Update state when the user logs in
  };

  // const handleLogout = () => {
  //   localStorage.removeItem('token'); // Remove the token
  //   setIsAuthenticated(false); // Update authentication state
  //   navigate('/login'); // Redirect to login page
  // };

  return (
    <>
      {showCookieBanner && <CookieBanner />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/amicaguesthouse/overview" element={<AmicaGuesthouse />} />
        <Route path="/amicaguesthouse/rooms" element={<Rooms />} />
        <Route path="/amicaguesthouse/map" element={<GuestMap />} />
        <Route path="/amicaguesthouse/contacts" element={<GuestContacts />} />
        <Route path="/amicarose/overview" element={<AmicaRose />} />
        <Route path="/amicarose/gallery" element={<Gallery />} />
        <Route path="/amicarose/map" element={<Map />} />
        <Route path="/amicarose/contacts" element={<Contacts />} />
        <Route path="/amicarose/" element={<AmicaRose />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route
          path="/channel/messages"
          element={<ProtectedRoute isAuthenticated={isAuthenticated} element={<Messages />} />}
        />
        <Route
          path="/housekeeping"
          element={<ProtectedRoute isAuthenticated={isAuthenticated} element={<Housekeeping />} />}
        />
        <Route path="/channel/reservations" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={<Reservations />} />} />
        <Route path="/channel/availability" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={<Availability />} />} />
        <Route path="/channel/rates" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={<Rates />} />} />
        <Route path="/channel/inbox" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={<Inbox />} />} />
      </Routes>
    </>
  );
};

const App = () => (
  <Router>
    <AppContent />
  </Router>
);

export default App;
